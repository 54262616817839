import React from "react";
import {Row, Col, Container} from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";
import {Link} from "gatsby";

const RefundPolicy = () => {
  return (
    <>
      <PageWrapper >
        <SEO title="Fair Refund Policy"></SEO>
        <div className="inner-banner pt-29 pb-6">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h2 className="title gr-text-2 mb-9">
                    A fair refund policy
                  </h2>
                  <p className="gr-text-8 mb-13">
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="main-block pb-8">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="10">
                <div className="px-lg-8 px-xl-3">
                  <div className="single-block mb-11">
                    <p className="gr-text-8">
                      Bad refund policies are infuriating. You feel like the company is just trying to rip you off. We never want our customers to feel that way, so our refund policy is simple: If you’re ever unhappy with our products for any reason, just contact our support team and we'll take care of you.
                    </p>
                  </div>

                  <div className="single-block mb-11">
                    <h3 className="gr-text-6 font-weight-bold pb-3">Examples of full refunds we’d grant.</h3>
                    <ul className="gr-text-8">
                      <li>If you were just charged for your next month of service but you meant to cancel, we’re happy to refund that extra charge.</li>
                      <li>If you forgot to cancel your account a couple months ago and you haven’t used it since then, we’ll give you a full refund for a few back months. No problem.</li>
                      <li>If you tried our products for a couple months and you just weren’t happy with it, you can have your money back.</li>
                    </ul>
                  </div>

                  <div className="single-block mb-11">
                    <h3 className="gr-text-6 font-weight-bold pb-3">Get in touch</h3>
                    <p className="gr-text-8">
                      At the end of the day, nearly everything on the edges comes down to a case-by-case basis. Send us a note, tell us what's up, and we'll work with you to make sure you’re happy.
                    </p>
                  </div>
                </div>
                <p>
                  <Link
                    to="/legal"
                    className="btn-link with-icon-left gr-text-blue gr-text-7 font-weight-bold mt-11"
                  >
                    <i className="icon icon-tail-left font-weight-bold"></i>
                      View All Policies
                    </Link>
                </p>
                <p className="text-muted gr-opacity-4 text-right pt-12"><small>Adapted from the Camelo open-source policies / CC BY 4.0</small></p>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default RefundPolicy;
